// 开发环境域名

// const host_development = 'http://192.168.1.7:91'
const host_development = 'https://www.lanyanhongyan.com'

export default {
    // 版本
    version: '1.4.4',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development
}


